.main-container {
    /* background: #fefefe; */
    /* box-shadow:6px 0px 16px -8px rgba(50, 50, 50, 0.3), -6px 0px 16px -8px rgba(50, 50, 50, 0.3); */
    /* box-shadow: inset 10px 0px 15px -6px rgba(50, 50, 50, 0.3); */
    /* border-left:1px solid #ccc; */
    /* border-right:1px solid #ccc; */
}
.logo {
    color:#ff7b00e1;
    font-size:4.5em;
}
.logo.logo-sm {
    color:#ff7b00e1;
    font-size:2em;
}
.logo.logo-md {
    color:#ff7b00e1;
    font-size:2.7em;
}
.logo.fix {
    position:absolute;
    top:24px;
    left:24px;
}
.offcanvas-body {
    padding:0 !important;
}
.offcanvas-header {
    padding-left:24px !important;
}
.editmode-enter-active .main-container,
.editmode-enter-done .main-container {
    /* border:none; */
    /* box-shadow:none; */
}
.editmode-enter-active #left-side,
.editmode-enter-done #left-side,
.editmode-enter-active #paging,
.editmode-enter-done #paging,
.editmode-enter-active .note:not(.editmode):not(.pin), 
.editmode-enter-done .note:not(.editmode):not(.pin),
.editmode-enter-active .add-new,
.editmode-enter-done .add-new {
    filter: grayscale(100%);
    opacity: 0.1;
    transition: 1000ms;
}
.editmode-enter-active .note,
.editmode-enter-done .note {
    /* border-bottom:none; */
}
.inputmode {
    max-height:0;
    overflow:hidden;
}
.inputmode-enter-active, 
.inputmode-enter-done {
    max-height:1000px;
    overflow:inherit;
    transition: 2000ms;
}
.headline h1 {
    font-weight:400;
    font-size:40px;
    margin:8px 0 0 0;
}
.headline svg {
    /* height:30px;
    margin-top:-8px; */
}
#paging {
    /* background:#fff; */
    /* border:1px solid #ddd; */
    border-left:0;
    border-bottom-right-radius:8px;
    border-top-right-radius:8px;
    /* box-shadow: inset 6px 0px 15px -10px #ccc; */
    /* margin:6px 0 6px 2px; */
    /* padding:1px 0 3px 0; */
    /* margin-right:-1px; */
    /* margin-top:16px; */
}
#paging button {
    border-radius: 0;
    font-size:12px;
    text-align:center;
    text-decoration:none;
    /* width:100%; */
}
#paging button.active {
    background:#0d6efd;
    color:white;
}
#paging button.active:hover {
    color:#0d6efd;
}
#paging button:hover {
    background:#efefef;
}
/* .note:not(.note-input-container) .ql-editor {
    margin-bottom:30px;
    margin-top:30px;
} */
.preview-backdrop:hover {
    /* background-color:white;
    border:2px dashed #ccc;
    margin:-2px; */
}
.ql-editor ul[data-checked=false] {
    background:#f7f7f7;
    border-left:3px solid #ccc;
}
.ql-editor ul[data-checked=true] {
    background:none;
    padding-left:8px !important;
}
.ql-editor ul[data-checked=false] > li::before {
    content: '\25A1' !important;
    font-size:20px;
    padding-left:12px;
    line-height:19px;
}
.ql-editor.preview ul > li::after {
    content: '\2807' !important;
    cursor:pointer !important;
    position:absolute;
    top:0;
    right:-5px;
    padding:0 5px;
    pointer-events: all;
}
.ql-editor.ql-blank::before {
    left:0px;
}
.ql-editor ul {
    padding-left:5px !important;
}
.ql-editor li {
    padding-right:20px;
}
.ql-editor li {
    position:relative
}
.ql-editor li:not(.ql-direction-rtl)::before {
    text-align: center !important;
}
.ql-mention-list-container {
    width: inherit !important;
}
.ql-mention-list-item {
    font-size:12px !important;
    line-height:28px !important;
}
.dropdown-item {
    font-size:13px;
}
.headline {
    position:relative;
}
.headline .breadcrumb {
    margin:0 0 0 4px;
    padding-bottom:8px;
}
.headline .breadcrumb-item+ .breadcrumb-item::before {
    content:none;
}
.headline .breadcrumb-item {
    color:#999;
    font-size:11px;
    padding-top:4px;
    padding-left:0 !important;
    padding-right:8px;
    text-decoration:none;
}
.headline .breadcrumb-item.active {
    color:#0d6efd;
    cursor:pointer;
}
.headline .toggle-icon {
    cursor: pointer;
    position:absolute;
    bottom:4px;
}
.headline .toggle-icon.toggle-tagcrumbs {
    right:24px;
}
.headline .toggle-icon.toggle-page-info {
    right:4px;
}
.page-info-tooltip .tooltip-inner {
    max-width:250px;
}