.note-tags {
    clear:both;
    float:left;
}
.note-tags .dropdown-menu {
    font-size:12px;
    margin-top:36px;
}
.note-tags .dropdown-menu .search-tag {
    font-size:12px;
}
.note .btn-addtag > a {
    color:#999;
    display:block;
    font-size:13px;
    padding:1px 0 2px 0;
    text-decoration:none;
}
.note .btn-addtag.exclamation > a {
    color:#be2d25;
}
.note .btn-hashtag{
    /* margin-right:5px; */
}
.note .btn-tag,
.note .btn-add-days {
    padding-top:2px;
}
.note .btn-tag:last-child {
    margin-right:5px;
}
.note .btn-tag.exclamation  {
    border-color:#be2d25;
    color:#be2d25;
}
.note .btn-tag.exclamation.active,
.note .btn-tag.exclamation:hover {
    background:#be2d25;
    border-color:#be2d25;
    color:white;
}
.note .btn-addtag.ok > a{
    color:#33b90a;
}
.note .btn-tag.ok {
    border-color:#33b90a;
    color:#33b90a;
}
.note .btn-tag.ok.active,
.note .btn-tag.ok:hover {
    background:#33b90a;
    border-color:#33b90a;
    color:white;
}
.note .btn-addtag.question > a {
    color:#ffa400;
}
.note .btn-tag.question {
    border-color:#ffa400;
    color:#ffa400;
}
.note .btn-tag.question.active,
.note .btn-tag.question:hover {
    background:#ffa400;
    border-color:#ffa400;
    color:white;
}
.note .btn-addtag.info > a {
    color:#008cba;
}
.note .btn-tag.info {
    border-color:#008cba;
    color:#008cba;
}
.note .btn-tag.info.active,
.note .btn-tag.info:hover {
    background:#008cba;
    border-color:#008cba;
    color:white;
}