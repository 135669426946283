button:focus {
    outline:0;
}
#filterbar {
    /* background:#f9f9f9; */
    /* border-bottom:1px solid #ddd; */
    /* margin:6px 0 6px 2px; */
    padding:10px 0 10px 0px;
    /* margin-right:-1px; */
}
#filterbar .filter-btn.btn {
    background:#eee;
    border-color:#ccc;
    color:black;
    /* margin-right:2px; */
    outline:none;
    padding:2px 8px 6px 8px;
    text-decoration: none;
}
#filterbar .filter-btn.btn.active {
    background:#0d6efd;
    color:white !important;
}
#filterbar .filter-btn.btn.exclamation {
    background:#be2d25;
    color:white;
}
#filterbar .filter-btn.btn.ok {
    background:#33b90a;
    color:white;
}
#filterbar .filter-btn.btn.question {
    background:#ffa400;
    color:white;
}
#filterbar .filter-btn.btn.info {
    background:#008cba;
    color:white;
}
#filterbar .filter-btn.btn.btn-text {
    color:black;
    font-size:13px;
    margin-left:5px;
    padding-top:6px;
    padding-bottom:7px;
}
.react-datepicker__close-icon::after {
    background:white;
    color:black;
}
.filter-btn:active,
.filter-btn:focus,
.filter-btn:visited {
    background-image: none;
    border: 0;
    box-shadow: none;
    outline: none;
}
#filterbar .filter-btn.btn.btn-sm svg {
}