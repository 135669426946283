input:focus {
    border-color:transparent;
    background-color: transparent;
    resize: none;
    outline: none;
}
body {
    background: #fefefe !important;
    color: #555;
    border-top:3px solid #008cba;
    font-size:13px !important;
    margin:0;
}
body.dark {
    background: #333;
    color: #999;
}
.clearboth {
    clear:both;
}
[ng-quill-editor] { 
    display: block; 
}
.ql-editor {
    font-size: 13px;
    line-height: 23px !important;
    /* margin-bottom:10px; */
    min-height: 23px;
    overflow-x: hidden;
}
.ql-editor {
    height: inherit;
    overflow-x:hidden;
    /* margin:18px 100px 0 0 !important; */
    /* padding:0 0 15px 0; */
    padding:0 !important;
}
.ql-editor.ql-blank::before {
    font-size: small;
    left:0 !important;
}
.ql-editor.preview > * {
    cursor: pointer !important;
    font-family: Helvetica, Arial, sans-serif;
}
h1,
.ql-editor h1 {
    color: #333;
    font-size: 1.8em;
    padding: 10px 0 6px 0;
}
h2, 
.ql-editor h2
{
    color: #777;
    font-size: 1.6em;
    font-weight: 100;
}
h3,
.ql-editor h3 {
    color: #777;
    font-size: 1.3em;
    font-weight: normal;
}
h4,
.ql-editor h4 {
    color: #777;
    font-size: 1.1em;
    font-weight: bold;
    text-decoration: underline;
}
h5,
.ql-editor h5 {
    color: #777;
    font-size: 1.6em;
    font-weight: normal;
    text-decoration: underline;
}
.react-datepicker-wrapper {
    float:none;
    width:inherit !important;
}
.react-datepicker-wrapper .datepicker-btn {
    background:none;
    border:none;
    color:black;
    font-size:13px;
}
.react-datepicker-popper {
    padding-top:2px !important;
}
.react-datepicker__close-icon::after {
    line-height:1px !important;
}
.b {
    font-weight:600 !important;
}
.mt--2 {
    margin-top:-2px;
}
.mt--4 {
    margin-top:-4px;
}
.mt--6 {
    margin-top:-6px;
}
.mt--8 {
    margin-top:-8px;
}
.color-star {
    color: #ffd900;
}
.color-exclamation {
    color:#be2d25;
}
.color-ok {
    color:#33b90a;
}
.color-question {
    color:#ffa400;
}
.color-info {
    color:#008cba;
}