.column-header div {
    float:left;
}
.column-header-1 {
    font-size:3em;
    text-align:right;
    width:60%;
}
.column-header-1 span {
    color:#000;
    font-weight: 100;
    width:40%;
}
.column-header-2 {
    line-height:16px;
    padding-top:16px;
    padding-left:10px;
    text-align:left;
    vertical-align:bottom;
}
.note {
    background:white;
    border:0px solid #eee;
    border-left-width:2px;
    /* border-radius:3px;; */
    /* cursor:pointer; */
    /* box-shadow: 0px 0px 2px 0px #ccc; */
    margin:0 0 15px 0;
    padding-left:1px;
    position:relative;

}
.note.list {
    margin-bottom:5px;
}
.note.task ul[data-checked] {
    padding-left:0;
}
.note.task .note-main-preview li.ql-indent-1,
.note.task .note-main-preview li.ql-indent-2,
.note.task .note-main-preview li.ql-indent-3,
.note.task .note-main-preview li.ql-indent-4 {
    padding-left:20px !important;
}
.note.exclamation {
    border-left-color:#be2d25;
}
.note.ok {
    border-left-color:#33b90a;
}
.note.question {
    border-left-color:#ffa400;
}
.note.info {
    border-left-color:#008cba;
}
.note:hover {
    border-left-width: 3px;
    padding-left:0;
    background:white;
    box-shadow: 0px 0px 3px 0px #bbb;
}
.note:hover .btn-edit {
    display:inline;
}
.note.editmode {
    /* border-bottom:1px solid white;
    border-left-width: 3px;
    box-shadow: 0px -10px 12px -2px #ccc;
    cursor:inherit;
    padding-left:0; */
    border-left-width: 3px;
    padding-left:0;
    background:white;
    box-shadow: 0px 0px 5px 0px #bbb;
}
.note .btn-save {
    /* margin-top:12px; */
    border-radius:0;
    border-top-right-radius:3px;
    position:absolute;
    right:-1px;
    top:-1px;
    z-index:999;
}
.note .btn-save.dropdown.btn-group {
    position:absolute;
    right:-1px;
    top:-1px;
    z-index:999;
}
.note .btn-save.dropdown.btn-group button {
    border-radius:0;
    border-top-right-radius:3px;
}
.note .btn-save.dropdown.btn-group button.btn-primary {
    height:28px;
    padding-top:2px;
}
.note .note-main-title-clickable {
    cursor:pointer;
}
/* .note .note-main-title-clickable:hover .btn-edit-title {
    display:inline;
} */
.note .note-main-title-clickable:hover .note-title {
    text-decoration:underline;
}
.note .note-title {
    /* font-size:20px; */
    font-weight:500;
}
.note .btn-edit-title {
    cursor:pointer;
    display:none;
    font-size:11px;
    height:18px;
    margin-bottom:2px;
    padding:0 3px;
    vertical-align:text-bottom;
}
.note .btn-edit-title svg {
    padding-bottom:2px;
}
.note .note-main-input {
    background:white;
    box-shadow: 0px 0px 2px 0px #bbb;
}
.note .note-input-container .ql-editor {
    margin-bottom: 2px;
    margin-top: 2px;
}
.note .note-input-title {
    border:0;
    color:black;
    font-size:20px;
    font-weight:500;
    padding:0;
    width:100%;
}
.note-main-preview {
    border-top:0px solid #c5c5c5;
}
.note .note-content-more {
    cursor:pointer;
    font-weight:700;
}
.note .progress-bar {
    position:relative;
    z-index: 1;
}
.note .progress-bar-task-container {
    height:3px;
    left:0;
    position:absolute;
    top:0;
    width:100%;
    z-index:1000;
}
.note .progress-bar-task {
    border-right:5px solid white;
    height:3px;
    float:left;
}
.note .note-thread-heading {
    /* position:relative;
    text-align:center */
}
.note .note-thread-heading:before {
    /* content: "";
    display: block;
    border-top: solid 1px #eee;
    width: 100%;
    height: 1px;
    position: absolute;
    top: 50%;
    z-index: 0; */
}
.note .note-thread-heading span {
    background: #fff;
    padding: 0 10px;
    position: relative;
    z-index: 1;
}
.note .note-thread-wrapper {
    background:#fafafa;
    border-top:1px solid #eee;
    margin-top:-8px;
    padding-left:10px;
}
.note .note-thread-content {
    background:#fafafa;
    border-left:2px solid #ccc;
    clear:both;
    margin-left:5px;
}
.note .note-thread-delete {
    float:left;
    font-size:13px;
    color:#be2d25;
    line-height:19px;
    padding:0 0 0 10px;
    text-decoration:none;
}
.note .note-threads-toggle {
    color:#000;
    cursor:pointer;
    float:left;
}
.note .thread-created-at {
    float:left;
}
.note .thread-created-at-clickable {
    cursor:pointer;
}
.note .paste-image {
    background:#f0f0f0;
    border-top:1px solid rgb(226, 226, 226);
}
.note .paste-image .ql-editor.ql-blank::before {
    font-size:11px;
}
.note .image-gallery-container {
    /* background:#eee; */
    border-bottom-left-radius:3px;
    border-bottom-right-radius:3px;
    border-top:1px solid #eee;
}
.note.editmode .image-gallery-container {
    background:#f0f0f0;
}
.note .image-gallery-item {
    cursor:pointer;
    float:left;
    height:30px;
}
.note.editmode .image-gallery-item {
    height:60px;
}
.note .image-gallery-container-shadow {
    display:none;
}
.note.editmode .image-gallery-container-shadow {
    box-shadow: inset 0px 8px 4px -8px #ccc;
    position: absolute;
    width: 100%;
    height: 5px;
    top: 0;
    left: 0;
}