
.label {
    clear:both;
    color:#999;
    font-weight:600;
    padding-top:12px;
}
.label-item {
    /* border-left:3px solid white; */
    border-radius:4px;
    clear:both;
    color:black;
    cursor:pointer;
    float:left;
    font-weight:400;
    margin:1px 0 0 15px;
    padding:4px 8px 4px 8px;
}
.label-item.star:hover,
.label-item.star.active {
    background:#ffd900;
    border-left:3px solid #ffd900;
    color:black;
    margin-left:12px;
}
.label-item.exclamation:hover,
.label-item.exclamation.active {
    background:#be2d25;
    border-left:3px solid #be2d25;
    color:white;
    margin-left:12px;
}
.label-item.exclamation.inactive {
    background:#efefef;
    border-left:3px solid #be2d25;
    color:black;
    margin-left:12px;
}
.label-item.info:hover,
.label-item.info.active {
    background:#008cba;
    border-left:3px solid #008cba;
    color:white;
    margin-left:12px;
}
.label-item.info.inactive {
    background:#efefef;
    border-left:3px solid #008cba;
    color:black;
    margin-left:12px;
}
.label-item.question:hover,
.label-item.question.active {
    background:#ffa400;
    border-left:3px solid #ffa400;
    color:white;
    margin-left:12px;
}
.label-item.question.inactive {
    background:#efefef;
    border-left:3px solid #ffa400;
    color:black;
    margin-left:12px;
}
.label-item.ok:hover,
.label-item.ok.active {
    background:#33b90a;
    border-left:3px solid #33b90a;
    color:white;
    margin-left:12px;
}
.label-item.ok.inactive {
    background:#efefef;
    border-left:3px solid #33b90a;
    color:black;
    margin-left:12px;
}
#tags {
    margin-left:32px;
}
.tag-section {
    clear:both;
    color:#999;
    cursor:pointer;
    font-weight:600;
    padding:6px;
}
.tag-section-spacer {
    cursor:default;
}
.tag-item {
    /* background:white; */
    /* border-bottom:1px solid #eee; */
    border-radius:3px;
    clear:both;
    /*float:left;*/
    font-size:13px;
    margin:1px 0 0 20px;
    padding:4px 8px 4px 8px;
    position:relative;
}
.tag-item span {
    cursor:pointer;
}
.tag-item:hover .tag-item-edit {
    display:inline;
}
.tag-item-edit {
    display:none;
    position:absolute;
    right:3px;
    top:3px;
}
.label-item:hover,
.label-item.active,
.tag-item.active,
.tag-item:hover {
    background:#03405517;
    color:black;
}
.tag-search-form {
    background: inherit;
    border:0;
    margin-left:4px;
    width:80%;
}
/* .search-tag {
    margin:10px 0 10px 6px;
}
.search-tag > input {
    background:#f9f9f9;
    border-color:#d5d5d5;
    border-radius:12px;
    font-size:12px;
    padding:3px 12px;
} */