#note-options {
    float: left;
    font-size: 13px;
    padding-top: 1px;
}
#note-options .option-btn {
    color:#999;
    font-size:13px;
    padding:1px 3px 4px 4px;
}
#note-options .option-btn:active,
#note-options .option-btn:focus,
#note-options .option-btn:visited {
    background-image: none;
    border: 0;
    box-shadow: none;
    outline: none;
}
#note-options .option-btn.active {
    color:black;
}
.datepicker-btn {
    color:#555;
    font-size: 12px;
    padding-left: 0;
}
.datepicker-btn span {
    display: block;
    float: right;
    /* padding-left: 4px; */
    padding-top: 2px;
}
#note-options .note-due-at {
    height:20px;
}
.react-datepicker__tab-loop {
    float:left;
}
.datepicker-btn {
    vertical-align:top;
}